<template>
  <div>
    <!-- picE4EBF2CF690A116E43F2DE296D48507A.jpg -->
    <img class="rounded img-large" src="">

    <p>
      Loads of LEGO Universe characters are featured on My LEGO Network, and more are on the way! Want to get in on all the fun, but don’t know how? This article presents the basics of My LEGO Network for you fans just getting started!
    </p>
    <p>
      My LEGO Network is a platform for creative fun! Part social networking and part cooperative gaming, it allows LEGO fans to safely socialize, compete, collaborate and show-off online!
      <br>You’ll find tons of ways to have fun on My LEGO Network. Here are just a few of the things you can do:
    </p>
    <p>
      - Create your own LEGO minifigure!
      <br>- Make new friends and trade stuff with them!
      <br>- Join missions and go on adventures!
      <br>- Show off your collections, achievements and LEGO creations!
      <br>- Interact with LEGO Universe characters like Professor Brickkeeper, Numb &nbsp;&nbsp;&nbsp;Chuck and Friendly Felix!
    </p>
    <p>
      New to My LEGO Network? Follow this link to <a href="http://mln.lego.com/en-gb/Help/default.aspx" target="”_blank”">learn more</a>! If you’re already a My LEGO Network expert, let everyone know what you like most about it!
    </p>
  </div>
</template>
